import { Controller } from "@hotwired/stimulus"

const debugOn = false

function log(a1, a2='', a3='', a4='') {
  if (debugOn) {
    console.log(a1, a2, a3, a4)
  }
}

export default class extends Controller {
  static targets = ["source", "destination"]
  static values = { show: String }

  connect() {
    this.toggle()
  }

  toggle() {
    const elType = this.sourceTarget.type
    log("[visibility_controller] elType: ", elType)

    if (elType === "checkbox") {
      this.toggleForCheckbox()
    } else {
      this.toggleForAny()
    }
  }

  toggleForAny() {
    const srcValue = this.sourceTarget.value
    const showValue = this.checkboxShowIfValue
    log("[visibility_controller] srcValue: ", srcValue, ", type: ", typeof srcValue)
    log("[visibility_controller] showValue = ", showValue)

    if (srcValue === showValue) {
      this.destinationTarget.hidden = false
    } else if (srcValue !== showValue) {
      this.destinationTarget.hidden = true
    }
  }

  toggleForCheckbox() {
    const srcValue = this.sourceTarget.checked
    const showValue = Boolean(this.showValue)
    log("[visibility_controller] srcValue: ", srcValue, ", type: ", typeof srcValue)
    log("[visibility_controller] showValue: ", showValue, ", type: ", typeof showValue)

    if (srcValue === showValue) {
      this.destinationTarget.hidden = false
    } else if (srcValue !== showValue) {
      this.destinationTarget.hidden = true
    }
  }
}