import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ["dropArea", "fileInput", "previewImage"];
  
  dragOver(event) {
    event.preventDefault();
    this.dropAreaTarget.classList.add("bg-blue-100", "border-blue-400");
  }

  dragLeave() {
    this.dropAreaTarget.classList.remove("bg-blue-100", "border-blue-400");
  }

  dropFile(event) {
    event.preventDefault();
    this.dropAreaTarget.classList.remove("bg-blue-100", "border-blue-400");
    this.fileInputTarget.files = event.dataTransfer.files;
    this.fileSelected({ target: this.fileInputTarget });
  }


  dropAreaClicked() {
    this.fileInputTarget.click();
  }

  fileSelected(event) {
    const file = event.target.files[0];
    if (file) {
      // Display a preview of the new image
      const reader = new FileReader();
      reader.onload = (e) => {
        this.previewImageTarget.src = e.target.result;
      };
      reader.readAsDataURL(file);

      // Upload the file to the server
      const formData = new FormData();
      formData.append("image[file]", file);

      fetch(this.data.get("url"), {
        method: "PUT",
        body: formData,
        headers: {
          "X-CSRF-Token": this.data.get("csrf"),
        },
      });
    }
  }
}
